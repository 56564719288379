





























































import { Component, Vue, Watch } from 'vue-property-decorator';
import { navigationMenuItems } from '@/misc/navigationMenuItems';
import { NavigationMenuItemInterface } from '@/interfaces/NavigationMenuItem.interface';
import { ROUTE_LOGIN } from '@/router/routes';
import {namespace} from "vuex-class";
import {settingsStoreActions} from "@/store/settings.store";
import {SlotSettings} from "@/interfaces/SlotSettings.interface";

const SettingsStore = namespace('settings');

@Component({
    components: {
        LanguageSelect: () => import(
            /* webpackChunkName: "LanguageSelect" */
            '@/components/shared/LanguageSelect.vue'),
    }
})
export default class HomeView extends Vue {

    @SettingsStore.Action(settingsStoreActions.GET_SETTINGS)
    public getSettings!: () => Promise<SlotSettings>;

    public navigationMenuItems: NavigationMenuItemInterface[] = [];
    public title: string = process.env.VUE_APP_TITLE!;

    @Watch('$route', { immediate: true })
    private onRouteChange() {
        this.$nextTick(() => {
            this.navigationMenuItems.forEach(item => {
                item.isActive = false;
            });
            const activeNavItem = this.navigationMenuItems.find(item => item.route === this.$route.name);
            activeNavItem!.isActive = true;
        });
    }

    public created() {
        this.getSettings();
        // Set up navigation items
        this.navigationMenuItems = navigationMenuItems.filter(item => this.$hasRole(item.neededUserRole));
    }

    /**
     * Event handler for click on nav item
     */
    public onNavItemClick(item: NavigationMenuItemInterface) {
        this.$router.push({ name: item.route });
    }

    /**
     * On logout handler
     */
    public async onLogout() {
        // await this.logoutAction();
        // Reset each store module
        Object
            // @ts-ignore
            .keys(this.$store._modulesNamespaceMap)
            .forEach(key => {
                this.$store.commit(`${key}CLEAR_STORE`);
            });
        await this.$router.push({ name: ROUTE_LOGIN });
        this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.LOGOUT')
    }
}
