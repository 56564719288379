var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[_c('div',{staticClass:"d-flex fill-height"},[_c('v-navigation-drawer',{staticClass:"relative",attrs:{"permanent":"","mini-variant":"","color":"primary","mini-variant-width":"90"}},[_c('v-list',{staticClass:"mt-4",attrs:{"dense":""}},[_vm._l((_vm.navigationMenuItems),function(item){return _c('v-list-item',{key:item.name,staticClass:"mb-4",attrs:{"ripple":false},on:{"click":function($event){return _vm.onNavItemClick(item)}}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"pa-4 nav-item d-flex align-center justify-center",class:{ 'is-active' : item.isActive}},[_c('v-icon',_vm._g({attrs:{"size":"24","color":item.isActive || hover ? 'primary' : 'background'}},on),[_vm._v(" "+_vm._s(item.icon)+" ")])],1)]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])])],1)}),_c('v-list-item',{key:"logout",staticClass:"mb-4",on:{"click":_vm.onLogout}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"pa-4 nav-item d-flex align-center justify-center"},[_c('v-icon',_vm._g({attrs:{"size":"24","color":hover ? 'primary' : 'secondary'}},on),[_vm._v(" fas fa-sign-out-alt ")])],1)]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('NAVIGATION_ITEMS.LOGOUT')))])])],1)],2),_c('div',{staticClass:"language-select-wrapper px-1"},[_c('language-select')],1)],1),_c('v-container',{staticClass:"fill-height align-start content-container pa-0",attrs:{"fluid":""}},[_c('router-view')],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }